import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import RecentReviews from '../components/RecentReviews';
import PopularArticles from '../components/PopularArticles';
import ReviewModule from '../components/ReviewModule';

const uuidv4 = require('uuid/v4');

const Tags = ({ pageContext, data }) => {
  const { tag, currentPage, numPages } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const prev = currentPage === 2 ? `/tags/${tag}` : `/tags/${tag}/${currentPage - 1}`;
  const next = `/tags/${tag}/${currentPage + 1}`;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`;
  return (
    <Layout>
      <SEO
        title={tag}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <RecentReviews className="d-none d-md-block" sideBar />
            <PopularArticles className="d-none d-md-block" sideBar />
          </div>
          <div className="col-sm-12 col-md-9 reviews-full">
            <h1>{tagHeader}</h1>
            <ul>
              {edges.map(({ node }) => {
                const { slug } = node.fields;
                const {
                  title, teaserimg, tags, snippet, date,
                } = node.frontmatter;
                const reviewData = {
                  slug,
                  teaserimg: teaserimg.childImageSharp.fluid,
                  title,
                  tags,
                  date,
                  snippet,
                };

                return (
                  <ReviewModule key={uuidv4()} data={reviewData} />
                );
              })}
            </ul>
            <div className="container mb-5">
              <div className="row">
                <div className="col-4 text-center">
                  <div className={currentPage === 1 ? ' d-none' : ''}>
                    <Link to={prev}><i className="fas fa-chevron-left" /></Link>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <Link to="/tags">All tags</Link>
                </div>
                <div className="col-4 text-center">
                  <div className={currentPage === numPages ? ' d-none' : ''}>
                    <Link to={next}><i className="fas fa-chevron-right" /></Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }),
};

Tags.defaultProps = {
  pageContext: {
    tag: 'tag',
  },
  data: {},
};

export default Tags;
export const pageQuery = graphql`
  query($skip: Int!, $limit: Int! $tag: String) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date
            snippet
            teaserimg {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
